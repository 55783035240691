time.icon
{
    
  font-size: 1em; /* change icon size */
  display: block;
  position: relative;
  width: 3em;
  height: 3em;
  background-color: #fff;
  margin-top: -2px;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  font-weight: normal;
  top: 0;
  padding: 0.1em 0;
  color: rgb(0, 0, 0);
  background-color:#aec1c9;
  height:20px;
  box-shadow: 0 1px 0 #73afc9;
}

time.icon em
{
  position: absolute;
  bottom: 0.05em;
  color: #527a8b;
}

time.icon span
{
  width: 100%;
  font-size: 1.2em;
  letter-spacing: -0.05em;
  padding-top: 1.2em;
  color: #2f2f2f;
}