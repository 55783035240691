.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0d0d0d;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0d0d0d, 0.1);
}

.ProseMirror ul {
  line-height: 4px;
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(#0d0d0d, 0.1);
  margin: 2rem 0;
}

.TipTapEditorContainer .ProseMirror{
  min-width: 200px;
  min-height: 100px;
}

div.TipTapContainer {
  margin: 20px 0;
  margin-left: 0rem;
  box-shadow: 0 0 5px -1px rgba(0,0,0,.43);
  border-radius: 4px;
  min-height: 200px;
  background-color: #fff;
}
.DrawerCover {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  color: black;
  z-index: 4;
  min-height: 410px;
}
div.TipTapMenu {
  padding: 4px 8px;
  display: flex;
  background-color: rgb(230, 237, 242);
  border-radius: 5px 5px 0 0;
}

div.ProseMirror {
  margin: 0px 10px 0px 10px;
  max-height: 400px;
  min-height: 200px;
  overflow: auto;
  font-size: 14px;
}


div.TipTapSources__list {
  max-height: 335px;
  overflow: auto;
}

div.ProseMirror:focus {
  outline: none;
}

button.editor {
  border: 2px solid black;
  border-radius: 4px;
  color: #0d0d0d;
  margin-right: 5px;
  font-size: 14px;
  border: unset;
  background-color: rgb(230, 237, 242);
  padding: 5px 10px;
}

button.editor.is-active {
  background-color: rgb(179, 208, 241);
}
button.editor.is-active:hover {
  background-color: rgb(179, 208, 241) !important;
}

.source,
custom-source-reference {
  color: brown !important;
  background-color: antiquewhite;
  font-weight: bold;
}

.footnote {
  color: #000;
  text-decoration: underline;
  font-weight: bold;
  vertical-align: super;
  font-size: 12px;
}
/*
   - when screen smaller than 1200 they need to go column
   - sidebar needs a redesign bellow this point?
*/
div.TipTapEditorContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
}

div.TipTapSourcePanel {
  border-right: #ccc 1px solid;
  padding: 10px;
  animation: slidein 0.3s forwards;
  transition: width 0.3s ease-in-out;
  background-color: #fff;
  position: relative;
  width: 560px;
  max-height: 385px;
}
div.TipTapSourcePanel + div {
  width: calc(100% - 560px);
}

@keyframes slidein {
  from {
    min-width: 0%;
  }
  to {
    min-width: 30%;
  }
}
div.DrawerEntry {
  display: flex;
  justify-content: flex-start;
  font-size: 14px;
  align-items: center;
  width: 100%;
}
div.DrawerEntry > button {
  margin-right: auto;
  margin-left: 24px;
}
div.DrawerEntry .quickBtns {
  margin-left: auto;
}
div.DrawerEntry .quickBtns button {
  width: 36px;
  height: 36px;
}
div.DrawerEntry .link {
  font-size: 14px;
  color: #002060;
  cursor: pointer;
  background-color: unset;
  border: unset;
  display: inline-block;
}
.TipTapIconFix span {
  padding-top: 1px;
}

div.DrawerEntry .link:hover {
  text-decoration: underline;
}
.DrawerBtn:hover {
  background-color: rgba(63, 81, 181, 0.04) !important;
}
div.TipTapSourcePanelMenu {
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  width: 100%;
  border-bottom: #ccc 1px solid;
}

div.TipTapSourcePanelMenu button {
  padding-right: 16px;
}

a.SourceDownloadLink {
  display: block;
  color: #0d0d0d;
  font-size: 10px;
  text-decoration: none;
}
a.SourceDownloadLink:hover {
  text-decoration: underline;
}

.SourceDownloadUpload {
  margin-top: 4px;
}

.SourceDownloadUploadFilename {
  font-size: 10px;
  color: #0d0d0d;
  margin-left: 5px;
  min-width: 88px;
}

.SourceButtons {
  display: flex;
  align-items: flex-end;
  flex-direction: row-reverse;
  gap: 2px;
  margin-top: 20px;
}

.CurrentSourceForm{
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
}

form h6.MuiTypography-root {
  margin: 0 0 10px 0;
}
form h5.MuiTypography-root {
  margin: 8px 0 0 0;
}
.form__card {
  padding: 10px 20px 0px;
}
.MuiPaper-rounded {
  overflow: visible !important;
}
.cm-top {
  margin-top: 0!important;
}
#jurisdiction-listbox li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
#jurisdiction-listbox li span:first-child {
  display: flex;
  margin: 4px 12px 4px 0;
}

ul[role="tree"] {
  padding-bottom: 100px;
}

.treeItem  .treeItem {
  padding: 6px 8px !important;
  height: 36px;
}
.MuiTreeItem-content {
  height: 36px;
}
.MuiCollapse-vertical.MuiTreeItem-group {
  margin-left: 10px;
}

.treeItem .MuiTreeItem-label {
  padding: 6px 0;
}

.tiptap-no-sources .TipTapMenu > span:first-child{
  display: none;
}
.TipTapMenu > span {
  height: 34px;

}
.treeItemOne.active > .MuiTreeItem-content {
  color: lightblue;
}
.FootnotesList li {
  cursor: pointer;
  font-size: 12px;
  margin: 4px 8px;
}

.list-unstyled {
  list-style: none !important;
  padding-left: 0;
}
.MuiTooltip-tooltip {
  margin-top: 6px !important;
}
/*tooltip fix*/
div[role="tooltip"] {
  pointer-events: none !important;
}
.editBtn__fix {
  position: relative;
  top: -2px;
}
.removeLine {
  position: absolute;
  width: 66%;
  height: 66%;
}
@media (max-width: 1060px) {
  .SourceButtons {
    display: flex;
    flex-direction: column;
  }
  .SourceButtons button {
    width: 100%;
  }
  .SourceButtons button:first-child {
    order: 2;
  }
}
.formPaper {
  margin-top: 12px !important;
}

.formPaperRevisions {
  margin-top: 12px !important;
}

.logo__animation {
  position: absolute;
  top: -250px;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  z-index: 123;
}

.css-1m07jl3-MuiButtonBase-root-MuiIconButton-root.Mui-disabled svg {
  fill: lightgray;
}

.deleteConfirmation__dialog button {
  background-color: rgb(230, 237, 242);
}
.deleteConfirmation__dialog button:hover {
  background-color: rgb(179, 208, 241);
}
.showInvalid {
  box-shadow: 0 0 18px -3px rgba(188, 33, 31, .63);
  border-radius: 4px;
}
.editorMain.tiptap-no-sources .ProseMirror {
  max-height: 155px;
  min-height: 155px;
}

.saveEditor__content {
  margin-left: auto;
}
.saveEditor__content button {
  margin-right: -6px;
}