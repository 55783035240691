@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  background-color: #eaeaea;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination > li {
  display: inline-block;
  padding-left: 0;
}
.pagination > li {
  list-style: none;
  border: 0.9px solid;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination > li.active > a {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color: #218838;
  color: white;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none !important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px !important;
  border-top-right-radius: 4px;
  display: none !important;
}
.ml-auto {
  display: block !important;
  margin-left: auto !important;
}
/* TODO - not so sure about this rule */
.MuiFormControl-root {
  width: 100%;
}
.submit__btn {
  margin-top: 20px !important;
}

.draft__btn {
  background-color: #1e7e34 !important;
}

.upload__btn {
  background-color: #5c1e7e !important;
}

.reject__btn {
  background-color: #f44336 !important;
}

.discard__btn {
  color: #f44336 !important;
  cursor: pointer;
  padding: 4px;
}

.discard__btn:hover {
  color: #f44336 !important;
  text-decoration: underline !important;
  padding: 4px;
}

.draftdetails__div {
  font-size: 14px;
  margin-top: -40px;
}

.MuiContainer-root {
  max-width: unset !important;
}

.form__errors,
.form__errors-TipTap {
  position: absolute;
  color: #f44336;
  font-size: 14px;
  margin-top: 28.75px;
  bottom: 24px; /* TODO: ??? */
}
#simple-tabpanel-5 {
  position: relative;
}
.TipTap__box {
  /*position: relative; */
}
.form__errors-TipTap {
  bottom: unset;
  top: 10px;
  right: 80px;
  left: unset;
}
.Drawer__header h4 {
  margin: 6px 0;
  padding-right: 68px;
}
.Drawer__header-actions {
  position: absolute;
  top: 0;
  right: 0;
}
.Drawer__save.css-1m07jl3-MuiButtonBase-root-MuiIconButton-root,
.Drawer__close.css-1m07jl3-MuiButtonBase-root-MuiIconButton-root {
  border: unset;
  box-shadow: unset;
  background: transparent;
  transform: scale(0.85);
  transform-origin: top right;
  cursor: pointer;
}

.form__actions {
  display: flex;
  justify-content: flex-end;
}

.submit__btn {
  margin-left: 16px !important;
}

button.btn-left-space {
  margin-left: 16px;
}

.DrawerBtn__refManager svg {
  margin-right: 6px;
}
.DrawerEntry:hover,
.DrawerEntry:focus-within {
  background-color: rgba(230, 237, 242, 0.45);
}

.DrawerEntry {
  padding: 4px 4px 4px 8px;
}
.addingNewSource .DrawerEntry.active {
  display: block;
}
.addingSource__text {
  margin-top: 0;
  text-align: center;
  font-size: 13px;
}

.customFootnote {
  color: black;
  font-weight: 500; /*
  border-bottom: 1px solid black; */
  cursor: pointer;
}
.customFootnote::after {
  content: attr(data-index);
  font-size: 0.85em;
  vertical-align: super;
  padding-left: 1px;
}
custom-source-reference::after {
  content: attr(data-order);
  font-size: 0.85em;
  vertical-align: super;
  padding-left: 1px;
}
.footnote__monitorID {
  margin-top: 16px !important;
}
.footnote__monitorID input {
  padding: 8px 12px 8px;
}
.footnote__monitorID label {
  top: -9px;
}
.footnote__monitorID label.Mui-focused {
  top: 0;
}
.Link__btn {
  font-size: 14px;
  display: inline-block;
  text-decoration: none;
  color: #3c8ad8;
  background-color: unset;
  border: unset;
  cursor: pointer;
}
.Link__btn:hover {
  text-decoration: underline;
}

/* Hide the actual input
   change background acordingly
*/
.PrivateSwitchBase-input,
.css-hyxlzm,
.MuiRadio-root {
  display: none !important;
}
.MuiRadio-root + span {
  padding: 2px 8px;
  font-size: 12px;
}
.TipTapSourcePanel .Mui-checked + span {
  background-color: rgb(179, 208, 241);
  color: #2f2f2f;
  border-radius: 4px;
}
div.radioGroup__form-container {
  width: auto;
  padding: 6px 10px;
  border-radius: 10px;
  margin-bottom: 8px;
}

.radioGroup__container {
  border-radius: 5px;
  padding: 6px 12px 6px 18px;
  background: rgb(230, 237, 242);
}

.FileUpload__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
  min-height: 46px;
}

.FileUpload__container > label {
  min-width: 51px;
}
.FileUpload__ac-container {
  position: relative;
  top: -2px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}
.FileUpload__ac-container .MuiAutocomplete-inputRoot .MuiAutocomplete-input,
.FileUpload__ac-container
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 12px;
  padding: 2.5px 4px 2.5px 12px;
}
.FileUpload__ac-container .css-twhkhu-MuiAutocomplete-root {
  width: 110px;
  max-width: 110px;
}
.FileUpload__languagesContainer {
  max-width: 120px;
}
.yearPicker__container {
  max-width: 100px;
  position: relative;
  top: -2px;
}
.yearPicker__container .MuiInputBase-root {
  height: 34.5px;
  top: 2px;
}
.yearPicker__container .css-1rnxufh-MuiFormLabel-root-MuiInputLabel-root {
  top: -6px;
  font-size: 14px;
}
.FileUpload__ac-container .css-smmfn9-MuiFormLabel-root-MuiInputLabel-root {
  top: -2px;
  font-size: 14px;
}
.uploadAction__btns {
  margin-left: auto;
}
.uploadAction__btns button,
.uploadAction__btns label {
  width: 34px;
  top: 2px;
  height: 34px;
  border-radius: 6px;
}
.addRemoveLang__btn {
  top: 3px;
  margin: 0 8px 0 4px;
}
.css-78trlr-MuiButtonBase-root-MuiIconButton-root.Mui-disabled svg {
  fill: lightgray;
}
.hideView {
  visibility: hidden !important;
}

.modal-tiptap-content {
  margin-bottom: 42px;
}

.draftDetails__div {
  margin-top: 12px;
  background-color: #ffffcf;
  padding:10px;
  border:#2f2f2f 1px solid;
}

.signikaF{

font-family: 'Signika', sans-serif;
}

.latoF {
  font-family: 'Lato', sans-serif;
}